<template>
	<div class="page-wrapper">
		<b-container fluid>
			<bo-page-title>
			</bo-page-title>
			<b-row>
				<b-col lg="4">
					<b-card no-body>
						<b-card-header>
							<b-row>
								<b-col lg="8">
									<h5 class="card-title">Add New</h5>
								</b-col>
							</b-row>
						</b-card-header>
						<b-card-body>
							<b-row>
								<b-col lg="12">
									<b-form-group label-for="articleTitle">
										<template #label>Page <span class="text-danger mr5">*</span></template>
										<v-select multiple placeholer="Select Page" v-model="selected_page" :options="page" />
									</b-form-group>
								</b-col>
								<b-col lg="12">
									<b-form-group label-for="articleTitle">
										<template #label>Ads Type<span class="text-danger mr5">*</span></template>
										<v-select placeholer="Select Ads Type" v-model="selected_type_ads" :options="type_ads" />
									</b-form-group>
								</b-col>

								<b-col lg="12">
									<b-form-group label-for="articleTitle">
										<template #label>Ads In Paragraph?<span class="text-danger mr5">*</span></template>
										<b-form-radio-group v-model="selected_p" :options="ads_p" class="mb-3" value-field="item"
											text-field="name" disabled-field="notEnabled">
										</b-form-radio-group>
									</b-form-group>
								</b-col>

								<b-col lg="12" v-if="selected_p == 'B'">
									<b-form-group label-for="articleTitle">
										<template #label>Position<span class="text-danger mr5">*</span></template>
											<v-select placeholer="Select Ads Type" v-model="selected_position" :options="p_position" />
									</b-form-group>
								</b-col>

								<b-col lg="12" v-else>
									<b-form-group label-for="articleTitle">
										<template #label>Select Paragraph<span class="text-danger mr5">*</span></template>
										<v-select placeholer="Select Ads Type" v-model="selected_p_type" :options="p_type" />
									</b-form-group>
								</b-col>

								<b-col md="12">
									<b-form-group label-for="articleTitle">
										<template #label>Ads Container<span class="text-danger mr5">*</span></template>
										<v-select placeholer="Select Category" v-model="selected_ads_size" :options="ads_size" />
									</b-form-group>
								</b-col>
								<b-col md="12">
									<b-form-group label-for="articleTitle">
										<template #label>
											Advertisement Code
											<b-badge variant="info" pill class="help-badge"
												v-b-tooltip.hover.right="'Insert your adsense code'">
												<i class="fas fa-question"></i>
											</b-badge>
										</template>
										<b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="7"
											max-rows="7">
										</b-form-textarea>
									</b-form-group>
								</b-col>
								<b-col cols="12">
									<b-form-group>
										<b-form-checkbox switch id="activeAd0" size="lg">
											Activate Ads?
										</b-form-checkbox>
									</b-form-group>
								</b-col>
							</b-row>
						</b-card-body>
						<template #footer>
							<div class="text-right">
								<b-button class="btn-rounded" variant="default" @click="cancelAddUpdate">Cancel
								</b-button>
								<b-button class="btn-rounded" variant="success" type="submit">Save</b-button>
							</div>
						</template>
					</b-card>
				</b-col>
				<b-col lg="8">
					<b-card no-body>
						<b-card-header>
							<b-row>
								<b-col lg="8">
									<h5 class="card-title">Ads Management List</h5>
								</b-col>
							</b-row>
						</b-card-header>
						<b-card-body>
							<div class="accordion" role="tablist">
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-0 variant="light">General <i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-0" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Ad after Featured Image</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd2" variant="success" size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below the Header</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd2" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below the Footer</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox switch id="activeAd2" variant="success" size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Above the Footer</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="wrap_nodata">
														<div class="thumb_nodata">
															<img src="/assets/images/no-data.png" />
														</div>
														<p>Data Not Available</p>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End General-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-1 variant="light">Homepage <i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Artikel Populer</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Artikel Unggulan</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Left Side Panduan</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Artikel Populer</td>
																	<td>729x90</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Artikel Populer #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Artikel Populer #2</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Home Page-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-2 variant="light">Post List <i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Subscribe Form</td>
																	<td>729x90</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End post List-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-3 variant="light">Single Post <i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Title</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>

																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Image</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>

																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Every 2 Paraghraps</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Newest Artikel</td>
																	<td>729x90</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Daftar Isi #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Daftar Isi #2</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Every 2 Paraghraps</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Single Post-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-4 variant="light">Detail Kamus Keuangan <i
												class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Title</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>

																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Image</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>

																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Every 2 Paraghraps</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Daftar Isi #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Below Daftar Isi #2</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Every 2 Paraghraps</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- Kamus Keuangan-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-5 variant="light">Comparison List <i
												class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Komparasi List-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-6 variant="light">Comparison Detail<i
												class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Detail Komparasi-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-7 variant="light">Tentang Kami<i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Tentang Kami-->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-8 variant="light">Hubungi Kami<i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Hubungi Kami -->
								<b-card no-body class="mb-1">
									<b-card-header header-tag="header" class="p-1" role="tab">
										<b-button block v-b-toggle.accordion-9 variant="light">Legal<i class="fa fa-angle-right"></i>
										</b-button>
									</b-card-header>
									<b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
										<b-card-body>
											<b-row>
												<b-col lg="12">
													<b-form-group>
														<b-form-radio-group v-model="selected" :options="ads_device" class="mb-3" value-field="item"
															text-field="name" disabled-field="notEnabled">
														</b-form-radio-group>
													</b-form-group>
												</b-col>
												<b-col lg="12" v-if="selected == 'A'">
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Mobile
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Beetwen Loop Post List</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
												<b-col lg="12" v-else>
													<div class="table-responsive">
														<table class="table table-hover table-striped table-bordered">
															<thead>
																<tr>
																	<th scope="col">Status</th>
																	<th scope="col">Ads Type</th>
																	<th scope="col">Position</th>
																	<th scope="col">Container</th>
																	<th scope="col">Action</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x600</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
																<tr>
																	<td>
																		<b-form-group>
																			<b-form-checkbox v-model="checked" switch id="activeAd1" variant="success"
																				size="lg">
																			</b-form-checkbox>
																		</b-form-group>
																	</td>
																	<td>
																		<div class="wrap_title_post">
																			<h3>Desktop
																			</h3>
																			<ul>
																				<li>2021-11-03 13:52:00</li>
																			</ul>
																		</div>
																	</td>
																	<td>Right Side Post List #1</td>
																	<td>300x250</td>
																	<td>
																		<a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
																		<a href="#" class="icon_action"><i class="ti-trash"></i></a>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</b-col>
											</b-row>
										</b-card-body>
									</b-collapse>
								</b-card>
								<!-- End Legal-->
							</div>
						</b-card-body>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
	import GlobalVue from '@/libs/Global.vue'
	export default {
		name: 'Article',
		extends: GlobalVue,
		components: {},
		data() {
			return {
				selected: 'A',
				ads_device: [{
						item: 'A',
						name: 'Mobile'
					},
					{
						item: 'B',
						name: 'Desktop'
					}
				],
				selected_p: 'B',
				ads_p: [{
						item: 'A',
						name: 'Yes'
					},
					{
						item: 'B',
						name: 'No'
					}
				],
				date: new Date(),
				checked: true,
				days: [],
				featured: 'not_accepted',
				seoRow: {},
				articleRow: {},
				articleData: [],
				rows: 30,
				perPage: 1,
				currentPage: 5,
				selected_category: 'All Category',
				category: [
					'All Category',
					'Pinjaman Online',
					'Asuransi',
					'Bank Digital',
					'Investasi',
					'Rencana Keuangan',
				],
				selected_sort: 'Sort By',
				sort: [
					'Sort By',
					'Most Viewed',
					'Least Viewed',
					'Oldest',
					'Newest',
				],
				selected_page: '--Select Page--',
				page: [
					'--Select Page--',
					'General',
					'Homepage',
					'Post List',
					'Single Post',
					'Kamus Keuangan',
					'Komparasi Produk',
					'Komparasi Detail',
					'Tentang Kami',
					'Legal',
					'Hubungi Kami'
				],
				selected_ads_size: 'Select Ads Size',
				ads_size: [
					'Select Ads Size',
					'300x250',
					'336x280',
					'728x90',
					'300x600',
					'320x100',
					'200x50',
					'320x20',
				],
				selected_p_type: '--Select Paragraph--',
				p_type: [
					'--Select Paragraph--',
					'Every Paragraph 2',
					'Every Paragraph 3',
					'Every Paragraph 4',
					'Every Paragraph 5',
					'Every Paragraph 6',
				],
				
				selected_type_ads: ' --Select Ads Type--',
				type_ads: [
					' --Select Ads Type--',
					'Mobile',
					'Desktop'
				],
				selected_position: '--Select Position--',
				p_position: [
					'--Select Position--',
					'Top Ads',
					'Ad after Featured Image',
					'Below The Header',
					'Ads Between'
				],
				selected_comment: 'Y',
				comment: [{
						item: 'Y',
						name: 'Yes'
					},
					{
						item: 'N',
						name: 'No'
					}
				],
			}
		}
	}
</script>